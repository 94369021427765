require('./bootstrap');

require('alpinejs');

try {
    window.$ = window.jQuery = require('jquery');

    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    };

    function linkify(inputText) {
        if (inputText === undefined) {
            return '';
        }

        var replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
    }

    editUsernameInfo = () => {
        $('#inputPopup').show().focus();
    };

    revertEditUsernameInfo = () => {
        let new_value = $('#usernameInput').val();

        $('#userNameText').html(new_value);
        $('#inputPopup').hide();
    };

    showPreviewForImage = (input, id) => {
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                $('#' + id)
                    .attr('src', e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    };

    editAboutMeInfo = () => {
        $('#editAboutMeButton').hide();
        $('#aboutMeSavedText').hide();
        $('#editAboutMeText').show().focus();
    };

    revertEditAboutMeInfo = () => {
        if ($('#editAboutMeText').length) {
            $('#editAboutMeButton').show();
            let new_value = $('#editAboutMeText').val();
            new_value = new_value.split("\n").join("<br>");

            $('#aboutMeSavedText').html(linkify(new_value));
            $('#editAboutMeText').hide();
            $('#aboutMeSavedText').show();
        }
    };

    addZeroIfSmall = (value) => {
        if (value < 10) {
            return `0${value}`
        } else {
            return value
        }
    }

    removeSuccessForUser = (obj) => {
        if (confirm('Are you sure you want to delete your success post?')) {
            let button = $(obj);
            button.attr('disabled', 'disabled');
            let successId = $(obj).attr('data-value');

            $.ajax({
                'url': `/dashboard/success/${successId}/remove`,
                'dataType': 'json',
                'method': 'get',
                success: function (result) {
                    if (result.status === DEFAULT_SUCCESS_STATUS) {
                        button.parents('li.successItem:first').detach();
                    }
                },
                error: function (result) {
                    if (result.message) {
                        alert(result.message);
                    }
                },
                complete: function () {
                    button.removeAttr('disabled');
                }
            });
        }
    };

    replaceEditAboutMeText = () => {
        let textarea = $('#editAboutMeText');

        let value = textarea.val();
        let old_value = $('#aboutMeSavedText').html();

        if (value === old_value) {
            textarea.removeAttr('disabled');
            revertEditAboutMeInfo();
        } else {
            $.ajax({
                'url': '/dashboard/update-about-me',
                'dataType': 'json',
                'method': 'post',
                'data': {
                    _token,
                    value
                },
                success: function (result) {
                    if (result.message) {
                        alert(result.message);
                    }
                },
                error: function (result) {
                    if (result.message) {
                        alert(result.message);
                    }
                },
                complete: function () {
                    textarea.removeAttr('disabled');
                    revertEditAboutMeInfo();
                }
            });
        }
    }

    replaceuserNameText = () => {
        let input = $('#usernameInput');

        //textarea.attr('disabled', 'disabled');
        let value = input.val();
        let old_value = $('#userNameText').html();

        if (value === old_value) {
            input.removeAttr('disabled');
            revertEditUsernameInfo();
        } else {
            $.ajax({
                'url': '/dashboard/update-username',
                'dataType': 'json',
                'method': 'post',
                'data': {
                    _token,
                    value
                },
                success: function (result) {
                    if (result.message !== '') {
                        alert(result.message);
                    } else {
                        revertEditUsernameInfo();
                    }
                },
                error: function (result) {
                    if (result.message) {
                        alert(result.message);
                    }
                },
                complete: function () {
                    input.removeAttr('disabled');
                }
            });
        }
    }

    if ($('#airdropDescription').length) {
        $('#airdropDescription').html(linkify($('#airdropDescription').text()));
    }
    if ($('#airdropSupportedSites').length) {
        $('#airdropSupportedSites').html(linkify($('#airdropSupportedSites').text()));
    }
    if ($('#airdropExpiry').length) {
        $('#airdropExpiry').html(linkify($('#airdropExpiry').text()));
    }
    if ($('#airdropDelivery').length) {
        $('#airdropDelivery').html(linkify($('#airdropDelivery').text()));
    }
    if ($('#airdropSpeeds').length) {
        $('#airdropSpeeds').html(linkify($('#airdropSpeeds').text()));
    }
    if ($('#airdropWarranty').length) {
        $('#airdropWarranty').html(linkify($('#airdropWarranty').text()));
    }

    $(document).ready(function () {
        if ($('.dropdown_logo .uploadAvatarIcon').length) {
            $.ajax({
                'url': '/dashboard/user-avatar',
                'dataType': 'json',
                'method': 'get',
                'data': {},
                success: function (result) {
                    if (
                        (result.answer !== undefined) &&
                        (result.answer.avatarLink !== undefined)
                    ) {
                        $('.dropdown_logo .uploadAvatarIcon').attr('src', result.answer.avatarLink);
                    }
                },
                error: function (result) {
                    if (result.message) {
                        alert(result.message);
                    }
                },
                complete: function () {
                    $('.dropdown_logo .uploadAvatarIcon').show();
                }
            });
        }

        $('#exchangeTokensButton').on('click', () => {
            let button = $(this);
            button.attr('disabled', 'disabled');
            let value = $('#exchangeTokensValue').val();

            if (value > 0) {
                $.ajax({
                    'url': '/dashboard/exchange-tokens',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        value
                    },
                    success: function (result) {
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            $('#exchangeTokensValue').val(0);
                            if (result.answer.tokens !== undefined) {
                                $('#dashboardTokens').html(result.answer.tokens);
                                if (result.answer.tokens === 0) {
                                    button.attr('disabled', 'disabled');
                                }
                            }
                            if (result.answer.balance !== undefined) {
                                $('#dashboardBalance').html(Math.round(100 * result.answer.balance) / 100);
                                //$('.full_balance').data('token-balance', Math.floor(result.balance));
                                updateTokenBalanceData(Math.floor(result.answer.tokens));
                            }
                        } else {
                            button.removeAttr('disabled');
                            alert(result.message);
                        }
                    },
                    error: function (result) {
                        button.removeAttr('disabled');
                        if (result.message) {
                            alert(result.message);
                        }
                    }
                });
            }
        });

        $('#inviteUserButton').on('click', () => {
            let button = $(this);
            button.attr('disabled', 'disabled');
            let value = parseInt($('#dashboardInvitesCount').html(), 10);

            if (value > 0) {
                let email = $('#inviteUserEmailValue').val();
                $.ajax({
                    'url': '/dashboard/send-invite',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        email
                    },
                    success: function (result) {
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            $('#inviteUserEmailValue').val('');
                            if (result.answer.invites_count !== undefined) {
                                $('#dashboardInvitesCount').html(result.answer.invites_count);
                                $('#dashboardInvitesCountText').html(result.answer.invites_count);

                                if (parseInt(result.answer.invites_count, 10) === 0) {
                                    $('#dashboardInviteUserBlock').html('');
                                }
                            }

                        } else {
                            alert(result.message);
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    },
                    complete: function () {
                        button.removeAttr('disabled');
                    }
                });
            }
        });

        // set links to dashboard/about me after load
        window.addEventListener('load', () => {
            var textarea = document.getElementById('editAboutMeText');
            if (textarea) {
                replaceEditAboutMeText();

                // checker of 2 lines in textarea max
                var lines = 2;
                textarea.addEventListener('keydown', e => {
                    let element = e.target;
                    let newLines = element.value.split("\n").length;

                    if ((e.key == 'Enter' && newLines >= lines) || newLines > lines) {
                        e.preventDefault();
                        textarea.style.cssText = 'color: red';
                    } else {
                        textarea.style.cssText = 'color: inherit';
                    }
                });
            }
        })

        // hide elements if click outside
        window.addEventListener('click', e => {
            // username popup
            if (typeof inputPopup !== 'undefined') {
                if (!inputPopup.contains(e.target) && !userNameText.contains(e.target)) {
                    $('#inputPopup').hide();
                }
            }
        })

        $('#editAboutMeText').on('blur', () => {
            replaceEditAboutMeText();
        });

        $('#usernameInput').on('blur', () => {
            replaceuserNameText();
        });

        $('#updateUsername').on('click', () => {
            replaceuserNameText();
        });

        if ($('#search_users').length) {

            $('#search_users').hide();
            document.documentElement.classList.remove('lock');

            $('#searchUsersButton').on('click', () => {
                $('#search_users').show();
                document.documentElement.classList.add('lock');
            });

            $('#search_users').on('click', () => {
                $('#search_users').hide();
                document.documentElement.classList.remove('lock');
            })

            $('#search_users > div').on('click', (event) => {
                event.stopPropagation();
            })

            $('#search_users #search-input').on('keyup', () => {
                let elem = $('#search_users #search-input');
                let value = elem.val();

                $.ajax({
                    'url': '/dashboard/search-user',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        value
                    },
                    success: function (result) {
                        if (result.message !== '') {
                            alert(result.message);
                        }

                        let users = result.answer.users;
                        let html = '';
                        if (users.length) {
                            html = '<ul class="found_list custom-scroll">';
                            users.forEach((item) => {
                                html += `<li class="item"><div class="card"><div class="rd50"><img src="${item.avatarLink}" alt="dollar"></div><div class="user_info"><h3 class="full_name">` + item.username + `</h3><p class="additional"><span class="nickname">` + item.discordUsername + `</span> &bull; <span class="period">` + item.createdTime + `</span></p></div></div><a class="btn r5 violet-btn userProfileLink" href="#" onclick="return false" data-value="${item.userId}">View Profile</a></li>`;
                            })
                            html += '</ul>';
                        }

                        $('#search_users_list').html(html);
                        userProfileLinkInit();
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    }
                });
            });
        }

        let userProfileLinkInit = function () {
            //$('#my_profile').hide();
            $('.userProfileLink').on('click', function () {
                let userId = $(this).data('value');

                if (userId) {
                    $('#my_profile').detach();

                    $.ajax({
                        'url': '/dashboard/user-profile-ajax/' + userId,
                        'dataType': 'json',
                        'method': 'get',
                        'data': {},
                        success: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }

                            try {
                                if (result.answer.html) {
                                    let html = `<div class="pop_up" id="my_profile" style="display: none">${result.answer.html}</div>`;
                                    $('main').append(html);
                                    $('#my_profile').show();
                                    document.documentElement.classList.add('lock');

                                    if ($('#myProfileAboutMeSavedText').length) {
                                        $('#myProfileAboutMeSavedText').html(linkify($('#myProfileAboutMeSavedText').text()));
                                    }

                                    $('#my_profile, #my_profile .cross.btn').on('click', () => {
                                        $('#my_profile').hide();
                                        document.documentElement.classList.remove('lock');
                                    })
                                    $('#my_profile > div').on('click', (event) => {
                                        event.stopPropagation();
                                    })
                                    // image lightbox galleries init
                                    let galleries = document.querySelectorAll('.lightgallery');
                                    galleries.forEach(el => {
                                        lightGallery(el, {
                                            selector: '.item'
                                        });
                                    })
                                }
                            } catch (e) {
                                if (e.message) {
                                    alert(e.message);
                                }
                            }
                        },
                        error: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                        }
                    });
                }
            });
        }

        if ($('.userProfileLink').length) {
            userProfileLinkInit();
        }

        if ($('#add_success').length) {
            $('#add_success').hide();
            document.documentElement.classList.remove('lock');
            $('#addSuccessButton').on('click', () => {
                $('#add_success').show();
                document.documentElement.classList.add('lock');
            });
            $('#add_success, #add_success .cross.btn').on('click', () => {
                $('#add_success').hide();
                document.documentElement.classList.remove('lock');
            })
            $('#add_success > div').on('click', (event) => {
                event.stopPropagation();
            });

            $('#addSuccessSubmitButton').on('click', (e) => {
                let button = $(this);
                button.attr('disabled', 'disabled');
                let caption = $('#success_caption').val();
                let link = $('#success_link').val() || '';
                let images = add_success_images_ids || [];

                if (success_caption.value.length != '') {
                    $.ajax({
                        'url': '/dashboard/add-success',
                        'dataType': 'json',
                        'method': 'post',
                        'data': {
                            _token,
                            caption,
                            link,
                            images
                        },
                        success: function (result) {
                            if (result.message) {
                                alert(result.message);
                            } else {
                                $('#add_success, #add_success .cross.btn').click();
                                $('.userProfileLink').click();
                            }
                        },
                        error: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                        },
                        complete: function () {
                            // clearing all inputs and dropzone
                            Dropzone.forElement('#imageUpload').removeAllFiles(true);
                            e.target.parentNode.querySelectorAll('input').forEach(input => {
                                input.value = '';
                            })
                            button.removeAttr('disabled');
                            add_success_images_ids = [];
                        }
                    });
                }
            });
        }

        if ($('#upload_avatar').length) {
            $('#upload_avatar').hide();
            document.documentElement.classList.remove('lock');
            $('#uploadAvatarIcon').on('click', () => {
                $('#upload_avatar').show();
                document.documentElement.classList.add('lock');
            });
            $('#upload_avatar, #upload_avatar .cross.btn').on('click', () => {
                $('#upload_avatar').hide();
                document.documentElement.classList.remove('lock');
            })
            $('#upload_avatar > div').on('click', (event) => {
                event.stopPropagation();
            });
        }

        if ($('#edit_card').length) {
            $('#edit_card').hide();
            document.documentElement.classList.remove('lock');
            $('#edit_card, #edit_card .cross.btn').on('click', () => {
                $('#edit_card').hide();
                document.documentElement.classList.remove('lock');
            })
            $('#edit_card > div').on('click', (event) => {
                event.stopPropagation();
            });
        }

        if ($('#subscribeToNotification').length) {
            $('#subscribeToNotification').on('click', () => {
                let button = $(this);
                button.attr('disabled', 'disabled');
                let value = 1;

                $.ajax({
                    'url': '/airdrops/send-notification',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        value
                    },
                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            button.hide();
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    },
                    complete: function () {
                        button.removeAttr('disabled');
                    }
                });
            });
        }

        if ($('#sourceCardList').length) {
            $('#sourceCardList input').on('change', function () {
                let value = $(this).val();
                if (value === 'new') {
                    $('#sourceCardListNewCard').show();
                } else {
                    $('#sourceCardListNewCard').hide();
                }
                // console.dir($('#sourceCardList input:checked').val());
            })
        }

        if ($('#airdropBlock').length) {
            let airdrop = document.getElementById('airdropBlock');
            let airdropId = airdrop.dataset.airdropId;
            let airdropType = airdrop.dataset.airdropType;

            let getUsersForAirdrop = function () {
                $.ajax({
                    'url': '/airdrops/get-users',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        airdropId
                    },
                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            if ($('#usersWaitingForRelease').length) {
                                let users = result.answer.users;
                                let html = '';
                                if (users.length) {
                                    users.forEach((item) => {
                                        html += `<div class="item"><div class="rd50"><img class="userProfileLink" data-value="${item.id}" src="${item.avatarLink}" alt="dollar"/></div></div>`;
                                    })
                                }

                                $('#usersWaitingForRelease').html(html);
                                userProfileLinkInit();

                                if (result.answer.isActive && result.answer.isUserSelected) {
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, REDIRECT_TO_CHECKOUT_INTERVAL);
                                }
                            }

                            if ($('#airdropActivePage').length) { // active page
                                if (result.answer.isUserSelected) {
                                    $('#userSelected').show();
                                    $('#userUnselected').hide();
                                    // redirect to checkout page
                                    setTimeout(function () {
                                        window.location.replace('/checkout');
                                    }, REDIRECT_TO_CHECKOUT_INTERVAL);
                                } else {
                                    $('#userSelected').hide();
                                    $('#userUnselected').show();
                                }
                            }

                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    },
                    complete: function () {
                        setTimeout(getUsersForAirdrop, 10000);
                    }
                });
            };

            getUsersForAirdrop();
        }

        if ($('#selectMonth').length) {
            let drawUsageGraph = function (month) {
                $.ajax({
                    'url': '/dashboard/get-usage-data',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        month
                    },
                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            updateGraph({
                                graph1: result.answer.graph1,
                                unit: result.answer.unit,
                                koef: result.answer.koef
                            })
                            if (result.answer.moreless) {
                                $('#usage_moreless').html(result.answer.moreless);
                                $('#usage_percent').html(result.answer.percent);
                            }
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    }
                });
            };

            $('#selectMonth').on('change', function () {
                let month = $(this).val();
                drawUsageGraph(month);
            });

            let month = $('#selectMonth').val();
            drawUsageGraph(month);
        }

        if ($('#adminTicketsList').length) {
            $('#adminTicketsList input[name="ticket_alias"]').on('change', function () {
                let ticket_id = $(this).attr('data-value');
                let alias = $(this).val();

                $.ajax({
                    'url': '/admin/tickets/update_alias',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        ticket_id,
                        alias
                    },
                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            $(this).focus();
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    }
                });
            });
        }

        if ($('#recent-pack-checkouts').length) {
            let airdrop = document.getElementById('airdropBlock');
            let airdropId = airdrop.dataset.airdropId;

            let getRecentPackCheckouts = function () {
                $.ajax({
                    'url': '/airdrops/recent-pack-checkouts',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        airdropId
                    },
                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            try {
                                let checkouts = result.answer.checkouts;
                                let html = '';
                                if (checkouts.length) {
                                    checkouts.forEach((item) => {
                                        html += `<div class="pack r5"><div class="icon rd50"><img class="userProfileLink" data-value="${item.userId}" src="${item.avatarLink}" alt="dollar"/></div><div class="wrap"><h4 class="name">` + item.recentText + `</h4><div class="profile"><a class="watch_profile btn userProfileLink" onclick="return false" href="#" data-value="${item.userId}">View <span class="name">${item.username}</span>’s Profile</a></div></div></div>`;
                                    })
                                }
                                $('#recent-pack-checkouts').html(html);
                                userProfileLinkInit();

                                // redirect to checkout page
                                // window.location.replace('/checkout');
                            } catch (e) {

                            }

                            $('#packs_quantity').html(result.answer.packs_left + ' IPs Left for Purchase');
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    },
                    complete: function () {
                        setTimeout(getRecentPackCheckouts, 12000);
                    }
                });
            };

            getRecentPackCheckouts();
        }

        if ($('#ticketMessages').length) {
            let ticketMessageInterval;

            let getMessagesForTicket = function () {
                let ticketId = $('#ticketsChat').data('value');

                $.ajax({
                    'url': '/tickets/messages',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        ticketId
                    },

                    success: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            try {
                                let messages = result.answer.messages;
                                let html = '';

                                if (messages.length) {
                                    html += '<ul class="messages">';
                                    messages.forEach((item) => {
                                        let className = item.isOwner ? 'from_me' : 'to_me';
                                        html += `<li class="${className}"><div class="circle rd50"><img class="userProfileLink" data-value="${item.userId}" src="${item.avatarLink}" alt="${item.createdTime}" title="${item.createdTimeFormatted}"></div><div class="info"><div class="message r5">`;
                                        if (item.imageLink !== '') {
                                            html += `<a href="${item.imageLink}" target="_blank"><img src="${item.imageLink}" style="max-height: 300px; max-width: 300px"  alt="${item.username}" title="${item.username}"/></a><br/>`;
                                        }
                                        html += `${item.message}</div><pre class="person">${item.username}</pre></div></li>`;
                                    })
                                    html += '</ul>';
                                }
                                $('#ticketMessages').html(html);
                                userProfileLinkInit();
                            } catch (e) {

                            }
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    }
                });
            };

            ticketMessageInterval = setInterval(getMessagesForTicket, 10000);
            getMessagesForTicket();

            let sendMessageForTicket = function () {
                let message = $('#input_text').val();
                let files = $('#attachedFile')[0].files;
                if (message || files.length) {
                    let ticketId = $('#ticketsChat').data('value');

                    let data = new FormData();
                    data.append('_token', _token);
                    data.append('message', message);
                    data.append('ticketId', ticketId);

                    if (files.length) {
                        data.append('hidden', files[0]);
                    }

                    $.ajax({
                        url: '/tickets/message',
                        dataType: 'json',
                        method: 'post',
                        contentType: false,
                        processData: false,
                        data,

                        success: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                            if (result.status === DEFAULT_SUCCESS_STATUS) {
                                try {
                                    if (ticketMessageInterval) {
                                        clearInterval(ticketMessageInterval);
                                        ticketMessageInterval = setInterval(getMessagesForTicket, 10000);
                                    }

                                    getMessagesForTicket();
                                    $('#input_text').val('');
                                } catch (e) {

                                }
                            }
                        },
                        error: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                        },
                        complete: () => {
                            $('#send_message').removeAttr('disabled');
                            $("#attachedFile").val(null);
                        }
                    });
                }
            }

            $('#send_message').on('click', () => {
                $('#send_message').attr('disabled', 'disabled');
                sendMessageForTicket();
            });

            $('#attachedFile').on('change', () => {
                $('#send_message').attr('disabled', 'disabled');
                sendMessageForTicket();
            });

        }

        if ($('#updateSocialNetwork').length) {
            $('#updateSocialNetwork').on('click', function () {
                let network = inputSocial.dataset.name;
                let link = $('#inputSocial').val();
                let buttonObj = $(this);

                if (network && link) {
                    $.ajax({
                        'url': '/dashboard/update-social-link/',
                        'dataType': 'json',
                        'method': 'post',
                        'data': {
                            _token,
                            network,
                            link
                        },
                        success: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }

                            try {
                                if (result.answer[network]) {
                                    $('#socialBlock').removeClass('active');
                                    $('a[data-name="' + network + '"]')
                                        .removeClass('empty')
                                        .addClass('filled')
                                        .attr('href', result.answer[network])
                                        .attr('data-link', result.answer['field']);
                                    deactivateSocialPopups($('a[data-name="' + network + '"]')[0]);
                                }
                            } catch (e) {
                                if (e.message) {
                                    alert(e.message);
                                }
                            }
                        },
                        error: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                        }
                    });
                }
            });
        }

        if ($('#payment-form').length) {
            let stripe = Stripe(STRIPE_PUBLIC_KEY);
            let airdrop = document.getElementById('stripeSecurePayment');
            let airdropId = airdrop.dataset.airdropId;

            let stripeCustomerId = null;
            let ajaxCustomerByEmail = null;

            let getStripeCustomerIdForEmail = function () {
                let email = $('#payment-form #email').val();
                if (email !== '') {
                    ajaxCustomerByEmail = $.ajax({
                        'url': '/stripe/get-customer-by-email',
                        'dataType': 'json',
                        'method': 'post',
                        'data': {
                            _token,
                            email
                        },
                        success: function (result) {
                            if (result.status === DEFAULT_SUCCESS_STATUS) {
                                try {
                                    stripeCustomerId = result.answer.customerId;
                                } catch (error) {
                                    alert(error.message);
                                }
                            } else {
                                if (result.message) {
                                    alert(result.message);
                                }
                            }
                        },
                        error: function (result) {
                            if (result.message) {
                                alert(result.message);
                            }
                        },
                        beforeSend: function () {
                            if (ajaxCustomerByEmail !== null) {
                                ajaxCustomerByEmail.abort();
                            }
                        }
                    });
                }
            }

            $('#payment-form #email').on('blur', () => {
                getStripeCustomerIdForEmail();
            });

            let email = $('#payment-form #email').val();
            if (email !== '') {
                getStripeCustomerIdForEmail();
            }

            let calculateOrderTotal = function () {
                let quantityBtn = $('#quantityTabs button.active');
                let quantity = $(quantityBtn).attr('data-value');
                let couponCode = $('#discount_code').val();
                let useTavernBalance = $('#useTavernBalance').is(':checked') || false;
                let proxyType = $('#purchaseBlock').attr('data-plan-type');

                $.ajax({
                    'url': '/stripe/calculate-order-total',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        couponCode,
                        airdropId,
                        quantity,
                        useTavernBalance: useTavernBalance ? 1 : 0,
                        proxyType
                    },
                    success: function (result) {
                        if (result.message !== '') {
                            $('#purchaseErrorBlock').html('<p>' + result.message + '</p>').show();
                        } else {
                        }
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            if (result.answer.orderQuantity) {
                                $('#planSummaryQuantity').html(result.answer.orderQuantity);
                            }
                            if (result.answer.orderSubtotal) {
                                $('#planSummaryOrderSubtotal').html(result.answer.orderSubtotal);
                                $('#orderSubtotal').html(result.answer.orderSubtotal);
                            }
                            if (result.answer.orderTotal) {
                                $('#orderTotal').html(result.answer.orderTotal);
                            }

                            if (result.answer.discountValue) {
                                $('#discountPart #discountValue').html('-' + result.answer.discountValue);
                                $('#discountPart').show();
                                if (result.answer.discountText) {
                                    $('#discountText').html('Discount Code (' + result.answer.discountText + ')');
                                }
                            } else {
                                $('#discountPart').hide();
                            }

                            if (result.answer.tavernValue) {
                                $('#tavernPart #tavernValue').html('-' + result.answer.tavernValue);
                                $('#tavernPart').show();
                                if (result.answer.tavernText) {
                                    $('#tavernText').html(result.answer.tavernText);
                                }
                            } else {
                                $('#tavernPart').hide();
                            }
                        } else {
                            $('#discountPart').hide();
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        $('#discountPart').hide();
                    },
                    beforeSend() {
                        $('#purchaseErrorBlock').hide();
                    }
                });
            };

            $('#apply_discount').on('click', () => {
                calculateOrderTotal();
            });

            $('#quantityTabs button').on('click', () => {
                calculateOrderTotal();
            });

            $('#useTavernBalance').on('change', () => {
                if ($('#useTavernBalance').is(':checked')) {
                    $('#discount_code').val('').attr('readonly');
                    $('#discount_code_text').hide();
                    $('#discount_code_block').hide();
                } else {
                    $('#discount_code').val('').removeAttr('readonly');
                    $('#discount_code_text').show();
                    $('#discount_code_block').show();
                }
                calculateOrderTotal();
            });

            let getTimeLeftForCheckout = function () {
                $.ajax({
                    'url': '/checkout/get-time-left',
                    'dataType': 'json',
                    'method': 'get',
                    'data': {},
                    success: function (result) {
                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            if (result.answer.timeLeft) {
                                if (result.answer.timeLeft.days !== undefined) {
                                    timeLeft.days = result.answer.timeLeft.days;
                                }
                                if (result.answer.timeLeft.hours !== undefined) {
                                    timeLeft.hours = result.answer.timeLeft.hours;
                                }
                                if (result.answer.timeLeft.minutes !== undefined) {
                                    timeLeft.minutes = result.answer.timeLeft.minutes;
                                }
                                if (result.answer.timeLeft.seconds !== undefined) {
                                    timeLeft.seconds = result.answer.timeLeft.seconds;
                                }
                            }
                        } else {
                            if (result.message) {
                                alert(result.message);
                            }
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                    },
                    complete: function () {
                        setTimeout(getTimeLeftForCheckout, 4000);
                    }
                });
            };

            getTimeLeftForCheckout();

            // Create an instance of Elements.
            let elements = stripe.elements([], 'en');

            let card = elements.create('card', {
                iconStyle: 'solid',
                style: {
                    base: {
                        iconColor: '#6e7bff',
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',

                        ':-webkit-autofill': {
                            color: '#fff',
                        },
                        '::placeholder': {
                            color: '#9D9DA7',
                        },
                    },
                },
            });

            card.mount('#stripe-checkout-form');

            // Handle form submission.
            let form = document.getElementById('payment-form');
            form.addEventListener('submit', function (event) {
                $('#submitPaymentForm').attr('disabled', 'disabled');
                event.preventDefault();

                let cardId = 'new';
                if ($('#sourceCardList').length) {
                    cardId = $('#sourceCardList input:checked').val();
                }

                if (cardId === 'new') {
                    stripe.createToken(card).then(function (result) {
                        if (result.error) {
                            // Inform the user if there was an error.
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = result.error.message;

                            $('#submitPaymentForm').removeAttr('disabled');
                        } else {
                            // Send the token to your server.
                            stripeTokenHandler(result.token, true);
                        }
                    });
                } else {
                    // Send the token to your server.
                    stripeTokenHandler(cardId, false);
                }
            });

            // Submit the form with the token ID.
            function stripeTokenHandler(token, isNewCard) {
                let couponCode = $('#discount_code').val();

                let proxyType = $('#purchaseBlock').attr('data-plan-type');

                let quantityBtn = $('#quantityTabs button.active');
                let quantity = $(quantityBtn).attr('data-value');

                let three_d_secure = document.getElementById('three_d_secure');
                let useTavernBalance = $('#useTavernBalance').is(':checked') || false;

                let tokenId = isNewCard ? token.id : token;
                $.ajax({
                    'url': '/stripe/create-checkout',
                    'dataType': 'json',
                    'method': 'post',
                    'data': {
                        _token,
                        token: tokenId,
                        stripeCustomerId,
                        couponCode,
                        airdropId,
                        quantity,
                        proxyType,
                        useTavernBalance: +useTavernBalance,
                        isNewCard: +isNewCard
                    },
                    success: function (result) {
                        if (result.message !== '') {
                            $('#purchaseErrorBlock').html('<p>' + result.message + '</p>').show();
                        } else {
                        }

                        if (result.status === DEFAULT_SUCCESS_STATUS) {
                            if (result.answer.code === 402) { // 3d secure
                                clearInterval(getTimeLeftForCheckout);

                                function on3DSComplete() {
                                    // Check the PaymentIntent

                                    stripe.retrievePaymentIntent(result.answer.client_secret)
                                        .then(function (result) {
                                            // Hide the 3DS UI
                                            $('#three_d_secure_popup').hide();
                                            $('#three_d_secure_popup #three_d_secure').html('');
                                            getTimeLeftForCheckout();

                                            if (result.error) {
                                                alert(result.error);
                                                // PaymentIntent client secret was invalid
                                            } else {
                                                $.ajax({
                                                    'url': '/stripe/confirm-checkout',
                                                    'dataType': 'json',
                                                    'method': 'post',
                                                    'data': {
                                                        _token,
                                                        paymentIntentId: result.paymentIntent.id
                                                    },
                                                    success: function (result) {
                                                        if (result.answer.code === 200) {
                                                            setTimeout(function () {
                                                                window.location.reload();
                                                            }, 1000);
                                                        }
                                                    },
                                                    error: function (result) {
                                                        console.dir(result);
                                                    },
                                                    complete: function () {
                                                        $('#submitPaymentForm').removeAttr('disabled');
                                                    }
                                                });
                                            }
                                        });
                                }

                                window.addEventListener('message', function (ev) {
                                    if (
                                        (ev.data === '3DS-authentication-complete') ||
                                        (ev.data.type === 'stripe-3ds-result')
                                    ) {
                                        on3DSComplete();
                                    }
                                }, false);

                                let iframe = document.createElement('iframe');
                                iframe.src = result.answer.redirect_url;
                                iframe.width = 600;
                                iframe.height = 500;
                                three_d_secure.appendChild(iframe);
                                $('#three_d_secure_popup').show();
                            } else if (result.answer.code === 200) {
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            }


                        } else {
                            $('#submitPaymentForm').removeAttr('disabled');
                        }
                    },
                    error: function (result) {
                        if (result.message) {
                            alert(result.message);
                        }
                        $('#submitPaymentForm').removeAttr('disabled');
                    }
                });
            }
        }

        if ($('#proxies_list').length){
            $('#check_all').on('click', function (e) {
                if ($(this).is(':checked', true)) {
                    $(".sub_proxy").prop('checked', true);
                } else {
                    $(".sub_proxy").prop('checked', false);
                }

            });

            $('#delete-selected').on('click', function (e) {
                var allVals = [];

                $(".sub_proxy:checked").each(function () {
                    allVals.push($(this).parents('tr').attr('data-proxy-ip'));
                });

                if (allVals.length <= 0) {
                    alert("Please select row.");
                } else {
                    var check = confirm("Are you sure you want to delete this row?");

                    if (check == true) {
                        var join_selected_values = allVals.join(",");

                        $.ajax({
                            'url': '/admin/user/proxies/mass-delete',
                            'dataType': 'json',
                            'method': 'post',
                            'data': {
                                _token,
                                proxy_ips: join_selected_values,
                                user_id: $('#check_all').attr('data-user-id'),
                                proxy_type: $('#check_all').attr('data-proxy-type')
                            },
                            success: function (data) {
                                $(".sub_proxy:checked").each(function () {
                                    $(this).parents("td").remove();
                                });
                                alert(data['success']);
                            },
                            error: function (data) {
                                console.log(data)
                                alert(data.responseJSON.error);
                            }
                        });

                        $.each(allVals, function (index, value) {
                            $('table tr').filter("[data-proxy-ip='" + value + "']").remove();
                        });
                    }
                }
            });
        }
    });
} catch (e) {
    console.dir(e.message);
}
